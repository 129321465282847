<template>
  <div>
    <b-modal id="bv-modal" size="lg" hide-footer>
      <template #modal-title> Agregar productos a orden de compra </template>
      <b-row>
        <b-col md="6">
          <v-select
            v-model="product"
            placeholder="Busca un Producto por sku o nombre"
            :filterable="false"
            :options="productsOptions"
            @search="fetchProducts"
          >
             <template #no-options="{}">
                            Sin Resultados,<b-button variant="link"  @click="$bvModal.show('bv-modal-product')">¿Desea registrar un producto?</b-button>
                          </template>
          </v-select>
        </b-col>
        <b-col md="3">
          <input
            type="text"
            class="form-control"
            placeholder="Cantidad"
            v-model="quantity"
          />
        </b-col>
        <b-col md="2">
          <button
            class="btn btn-primary"
            @click="add"
            :disabled="product == null || quantity == null ? true : false"
          >
            Agregar
          </button>
        </b-col>
      </b-row>
      <b-row v-if="data.length > 0">
        <b-col class="table-responsive mt-4">
          <b-table hover :items="data" :fields="columns">
            <template v-slot:cell(product_id)="data">
              {{ data.item.product_id.label }}
            </template>
            <template v-slot:cell(option)="data">
              <b-button
                v-b-tooltip.hover
                title="Eliminar"
                @click="remove(data.item)"
                variant=" iq-bg-danger"
                size="sm"
                ><i class="ri-delete-bin-line ml-1"></i
              ></b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <hr />
      <b-button variant="primary" block @click="submit()">Guardar</b-button>
    </b-modal>
    <div></div>
     <b-modal size="lg" id="bv-modal-product" hide-footer>
        <template #modal-title> Agregar nuevo producto</template>
        <b-row>
            <register-product origin="1" v-on:hide="hide"/>
        </b-row>
      </b-modal>
  </div>
</template>
 <script>
import { mapActions } from "vuex";
import RegisterProduct from "@/views/Admin/Products/forms/createOrUpdate.vue";
import { core } from "@/config/pluginInit";
export default {
  components: {
    RegisterProduct
  },
  mounted() {
    core.index();
  },
  data() {
    return {
      productsSelect: [],
      product: null,
      quantity: null,
      data: [],
      columns: [
        { label: "Nombre", key: "product_id", class: "text-center" },
        { label: "Cantidad", key: "quantity", class: "text-center" },
        { label: "Opciones", key: "option", class: "text-center" },
      ],
    };
  },
  methods: {
      ...mapActions({
          newproducts : 'orders/addProductsPurchaseOrders'
      }),
       hide(res){
     /* this.product = { value: res.id, label: res.name }*/
      this.$bvModal.hide("bv-modal-product");
    },
    showModal(item) {
      this.$bvModal.show("bv-modal");
    },
    async fetchProducts(search, loading) {
      if (search && search.length > 2) {
        loading(true);
        const products = await this.$store.dispatch("products/listProducts", {
          search,
        });
        loading(false);
        this.productsSelect = products;
      } else {
        this.productsSelect = [];
      }
    },
    add() {
      this.data.push({ product_id: this.product, quantity: this.quantity });
      this.product = null;
      this.quantity = null;
    },
    remove(item) {
      const index = this.data.indexOf(item);
      this.data.splice(index, 1);
    },
    async submit() {
      let payload = this.data.map((item) => ({
        product_id: item.product_id.value,
        quantity: item.quantity,
      }));
      try {
        await this.newproducts({id: this.$route.params.id, products: payload});
        core.showSnackbar("success", "Producto agregado correctamente");
        this.$bvModal.hide("bv-modal");
        this.product = null;
        this.quantity = null;
        this.data = []
      } catch (error) {
        console.log(error)
      /*  const errors = error.response.data.errors;
        for (const key in errors) {
          core.showSnackbar("error", errors[key][0]);
          return false;
        }*/
      }
    },
  },
  computed: {
    productsOptions() {
      if (this.productsSelect.length) {
        return this.productsSelect.map(({ id: value, name: label }) => ({
          value,
          label,
        }));
      }
      return [];
    },
  },
};
</script>