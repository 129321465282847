<template>
  <b-container fluid v-if="getOrdersRequest">
    <b-row>
      <b-col sm="12" md="6" lg="4">
        <b-row>
          <b-col class="profile-center">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title mt-3">
                  Orden de Compra {{ getOrdersRequest.correlative }}
                </h4>
              </template>
              <template v-slot:body>
                <b-row align-v="center">
                  <b-form-group class="col-md-6">
                    <h6 class="custom-text">FECHA:</h6>
                    <h5>{{ getOrdersRequest.created_at }}</h5>
                  </b-form-group>
                  <b-form-group class="col-md-6">
                    <h6 class="custom-text">ÁREA:</h6>
                    <h5>{{ getOrdersRequest.area.name }}</h5>
                  </b-form-group>
                  <b-form-group class="col-md-6">
                    <h6 class="custom-text">ESTADO:</h6>
                    <h5>{{ getOrdersRequest.status_description }}</h5>
                  </b-form-group>
                  <b-form-group class="col-md-6">
                    <h6 class="custom-text">SOLICITANTE:</h6>
                    <h5>{{ getOrdersRequest.user_applicant.fullname }}</h5>
                  </b-form-group>
                  <b-form-group class="col-md-12">
                    <h6 class="custom-text">PROVEEDOR:</h6>
                    <h5>
                      {{
                        getOrdersRequest.provider
                          ? getOrdersRequest.provider.business_name
                          : "Sin proveedor"
                      }}
                    </h5>
                  </b-form-group>
                  <b-form-group class="col-md-12">
                    <h6 class="custom-text">DESCRIPCIÓN:</h6>
                    <h5>{{ getOrdersRequest.description }}</h5>
                  </b-form-group>
                  <b-col cols="12">
                    <b-button
                      variant="warning"
                      block
                      @click="$refs.Update.showModal(getOrdersRequest)"
                      class="shadow mb-3"
                      ><i class="ri-edit-box-line"></i> Actualizar</b-button
                    >
                  </b-col>
                  <b-col cols="12">
                    <b-button
                      :href="url"
                      target="_blank"
                      variant="none"
                      block
                      class="bg-light mb-3"
                      ><i class="ri-file-pdf-line"></i> Descargar PDF</b-button
                    >
                  </b-col>

                  <b-col cols="6">
                    <b-button
                      variant="primary"
                      @click="$refs.comentaries.show('oc', $route.params.id)"
                      ><i class="ri-chat-new-line"></i> Agregar Comentarios
                    </b-button>
                  </b-col>
                  <b-col cols="6">
                    <b-button
                      variant="dark"
                      @click="$refs.list.show('oc', $route.params.id)"
                      ><i class="ri-chat-smile-3-line mt-1"></i> Ver Comentarios
                    </b-button>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </b-col>
      <!-- Tabla de productos -->
      <b-col sm="12" md="6" lg="8">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Productos</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="$refs.add.showModal()"
              >Agregar Productos</b-button
            >
          </template>
          <template v-slot:body>
            <b-row>
              <b-col class="table-responsive">
                <b-table
                  hover
                  :items="getOrdersRequest.products"
                  :fields="columns"
                >
                  <template v-slot:cell(option)="data">
                    <b-button
                      v-b-tooltip.hover
                      title="Comentarios de productos"
                      variant=" iq-bg-info mr-1"
                      size="sm"
                      @click="$refs.list.show('poc', $route.params.id)"
                      v-show="data.item.comentaries"
                      ><i class="ri-message-3-line"></i>
                    </b-button>
                    <b-button
                      v-b-tooltip.hover
                      title="Editar cantidad"
                      v-if="getOrdersRequest.status_description === 'Creada'"
                      variant=" iq-bg-warning mr-1"
                      size="sm"
                      @click="show(data.item)"
                      ><i class="ri-ball-pen-fill m-0"></i>
                    </b-button>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
      <b-col md="12">
        <order-quotations v-on:loadData="loadData" type="order" />
      </b-col>
    </b-row>
    <list ref="list" :correlative="getOrdersRequest.correlative" />
    <div>
      <productsModals ref="add" />
      <b-modal
        id="edit"
        hide-footer
        scrollable
        :title="'Editar orden de compra'"
      >
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <b-row>
              <b-form-group
                class="col-md-12"
                label="Cantidad:"
                label-for="Cantidad"
              >
                <ValidationProvider
                  name="Cantidad"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-input
                    type="number"
                    v-model="form.quantity"
                    placeholder="Nueva cantidad"
                    :class="
                      errors.length > 0
                        ? ' is-invalid'
                        : '' || form.quantity <= 0
                        ? 'is-invalid'
                        : ''
                    "
                  >
                  </b-form-input>
                  <div class="invalid-feedback">
                    <span v-if="form.quantity <= 0"
                      >La cantidad no puede estar vacia, valor cero (0) o
                      valores negativos.
                    </span>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </b-form-group>
              <b-form-group
                class="col-md-12"
                label="Comentario:"
                label-for="Comentario"
              >
                <ValidationProvider
                  name="Comentario"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-textarea
                    id="comentaries"
                    v-model="form.commentary"
                    placeholder="Ingresar comentario"
                    rows="1"
                    max-rows="1"
                    :class="errors.length > 0 ? ' is-invalid' : ''"
                  ></b-form-textarea>
                  <div class="invalid-feedback">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-row>
            <hr />
            <b-button variant="primary" type="submit" class="mr-2"
              >Guardar</b-button
            >
            <b-button
              variant=" iq-bg-danger mr-1"
              class="mr-2"
              @click="$bvModal.hide('edit')"
              >Cerrar</b-button
            >
          </form>
        </ValidationObserver>
      </b-modal>
    </div>
    <Comentaries ref="comentaries" />
    <modals ref="Update" />
  </b-container>
</template>
<script>
import { core } from "@/config/pluginInit";
import { mapActions, mapGetters } from "vuex";
import OrderQuotations from "@/components/core/orderQuotations/QuotationsList.vue";
import list from "@/components/core/modals/ListComentaries.vue";
import productsModals from "@/components/core/modals/PurchaseOrdersProductsModals.vue";
import Comentaries from "@/components/core/modals/Comentaries.vue";
import modals from "@/components/core/modals/OrdersUpdateModals.vue";
export default {
  name: "ShowOrders",
  components: {
    OrderQuotations,
    productsModals,
    list,
    Comentaries,
    modals,
  },
  async created() {
    this.$store.commit("SET_BREADCRUMB", this.items);
    core.index();
    await this.showPurchaseOrders(this.$route.params.id);
  },
  data() {
    return {
      id: this.$route.params.id,
      url:
        process.env.VUE_APP_API_URL +
        `/api/purchase_orders/${this.$route.params.id}/pdf-export`,
      orders: null,
      products: [],
      form: {
        id: this.$route.params.id,
        product_id: "",
        quantity: "",
        commentary: "",
      },
      columns: [
        { label: "Nombre", key: "name", class: "text-center" },
        { label: "Cantidad", key: "quantity", class: "text-center" },
        { label: "Opciones", key: "option", class: "text-center" },
      ],
      items: [
        {
          html: "Inicio",
          to: "/",
        },
        {
          html: "Órdenes de Compras",
          to: "/orders_requests/list",
        },
        {
          text: "Detalles de orden de Compra",
          active: true,
        },
      ],
    };
  },
  methods: {
    async loadData() {
      // const orders = await this.showPurchaseOrders(this.id);
      // this.orders = orders;
      // this.products = this.orders.products.map((product) => {
      //   return {
      //     id: product.id,
      //     name: product.name,
      //     quantity: product.quantity,
      //     comentaries: product.commentaries,
      //   };
      // });
    },
    async show(item) {
      this.form.product_id = item.id;
      this.form.quantity = item.quantity;
      this.form.commentary = "";
      this.$bvModal.show("edit");
    },
    async onSubmit() {
      try {
        await this.$store.dispatch("orders/updatePurchaseOrders", this.form);
        core.showSnackbar("success", "Cantidad Editada correctamente");
        this.$bvModal.hide("edit");
      } catch (error) {
        const errors = error.response.data.errors;
        for (const key in errors) {
          core.showSnackbar("error", errors[key][0]);
          return false;
        }
      }
    },
    ...mapActions({
      showPurchaseOrders: "orders/showPurchaseOrders",
    }),
  },
  computed: {
    ...mapGetters({
      getOrdersRequest: "orders/getOrdersRequest",
    }),
  },
};
</script>
<style>
.custom-text {
  font-size: 0.7em !important;
  color: #a2a0a0 !important;
}
</style>
