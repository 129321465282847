<template>
  <div>
    <b-modal id="bv-modal-update" no-close-on-backdrop hide-footer>
      <template #modal-title>
        Actualizar proveedor, terminos y condiciones en orden de compra
        {{ item.correlative }}
      </template>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <b-row>
            <!-- PROVIDERS -->
            <b-form-group
              class="col-md-12"
              label-for="Proveedor"
              label="Proveedor"
            >
              <ValidationProvider
                name="Proveedor"
                rules="required"
                v-slot="{ errors }"
              >
                <v-select
                  :filterable="false"
                  v-model="form.provider_id"
                  placeholder="Seleccionar..."
                  :options="providersOptions"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                >
                  <template #no-options="{}"> Sin Resultados </template>
                </v-select>
                <div class="invalid-feedback">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </b-form-group>
            <!-- TERMS AND CONDITIONS -->
            <b-form-group
              class="col-md-12"
              label-for="Terminos y condiciones"
              label="Terminos y condiciones"
            >
              <ValidationProvider
                name="Terminos y condiciones"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-textarea
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                  v-model="form.terms"
                  placeholder="Ingresar terminos y condiciones"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
                <div class="invalid-feedback">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </b-form-group>
          </b-row>
          <hr />
          <b-button variant="primary" block type="submit">Guardar</b-button>
        </form>
      </ValidationObserver>
    </b-modal>
    <div></div>
  </div>
</template>
 <script>
import { core } from "@/config/pluginInit";
import { mapActions, mapGetters } from "vuex";
import products from '../../../store/Admin/products';
export default {
  async mounted() {
    core.index();
    await this.fechProviders();
  },
  data() {
    return {
      form: {
        id: "",
        type: 1,
        area_id: "",
        product_id: [],
        description: "",
        provider_id: "",
        terms: "",
      },
      item: "",
    };
  },
  methods: {
    ...mapActions({
      storeArea: "area/storeArea",
      fechProviders: "providers/fechProviders",
      convertPurchase: "orders/storePurchaseOrders",
    }),
    showModal(item) {
      if (item) {
        this.form = {
          id: item.id,
          type: 1,
          area_id: item.area,
          products: item.products ? item.products.map(({ id: product_id, quantity: quantity }) => ({
            product_id,
            quantity,
          })) : '',
          description: item.description,
          provider_id: item.provider ? {
            value: item.provider.id,
            label: item.provider.business_name,
          } : '',
          terms: item.terms,
        };
        this.item = item;
      }
      this.$bvModal.show("bv-modal-update");
    },
    async onSubmit() {
      try {
        let {id, type, area_id, products, description, provider_id, terms} = this.form
        let payload = {
          id: id,
          type: type,
          area_id: area_id.id,
          products: products,
          description: description,
          provider_id: provider_id.value,
          providers_name: provider_id.label,
          terms: terms,
        };
        const res = await this.convertPurchase(payload);
        core.showSnackbar(
          "success",
          "Orden de Compra actualizada Correctamente"
        );
        this.$bvModal.hide("bv-modal-update");
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    ...mapGetters({
      getProviders: "providers/getProviders",
    }),
    providersOptions() {
      if (this.getProviders) {
        return this.getProviders.map(({ id: value, business_name: label }) => ({
          value,
          label,
        }));
      }
    },
  },
};
</script>